import React from 'react';

export const Assets = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' className='assets-icon'>
		<g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
			<path d='M2.562 10A4 4 0 0 0 .5 13.5v2h15v-2a4 4 0 0 0-2.062-3.5'/>
			<rect width='7' height='9' x='4.5' y='.5' rx='3.5'/>
		</g>
	</svg>
);
