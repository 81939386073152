import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
	FooterContactContainer,
	FooterLink,
	FooterContent,
	FooterImage,
	FooterImageInnerContainer,
	FooterSocialLinkContainer,
	FooterTitle,
	ImageContainer,
	FooterGDPR,
} from './';

import { Context } from '../../context';

const footerQuery = graphql`
	query FooterQuery {
		allStoryblokEntry(filter: {field_component: {eq: "Footer"}}) {
			edges {
				node {
					id
					field_component
					content
					lang
				}
			}
		}
	}
`;

const A2FooterSection = () => {
	const { allStoryblokEntry: { edges } } = useStaticQuery(footerQuery);
	const language = useContext(Context);

	const edgeNode = edges.find(edge => edge.node.lang === language?.language);
	if(!edgeNode) return null;

	const { node } = edgeNode;
	const {
		contact_us,
		social_media_links,
		stay_connected,
		contact_links,
		gdpr,
		logo_links,
	} = JSON.parse(node.content);

	return (
		<footer className='footer'>
			<FooterContent>
				<ImageContainer>
					{logo_links.map(link => (
						<FooterLink key={link._uid} url={link.url} linkType={link.link_type}>
							{link.image?.filename ? (
								<FooterImageInnerContainer>
									<FooterImage src={link.image.filename} alt={link.image.alt || link.label} />
									<span className='sr-only'>
										{link.label}
									</span>
								</FooterImageInnerContainer>
							) : (
								<>{link.label}</>
							)}
						</FooterLink>
					))}
				</ImageContainer>
				<FooterContactContainer>
					<FooterTitle>{ contact_us }</FooterTitle>
					{contact_links.map(link =>(
						<FooterLink key={link._uid} url={link.url} linkType={link.link_type}>
							{link.label}
						</FooterLink>
					))}
				</FooterContactContainer>
				<FooterSocialLinkContainer>
					<FooterTitle>{ stay_connected }</FooterTitle>
					{social_media_links.map(link =>(
						<FooterLink key={link._uid} url={link.url} linkType={link.link_type}>
							{link.label}
						</FooterLink>
					))}
				</FooterSocialLinkContainer>
			</FooterContent>
			{gdpr.map(link =>(
				<FooterGDPR key={link._uid}>
					<FooterLink gdpr url={link.url} linkType={link.link_type}>
						{link.label}
					</FooterLink>
				</FooterGDPR>
			))}
		</footer>
	);
};

export default A2FooterSection;
