import { graphql, Link, StaticQuery } from 'gatsby';
import React, { useState, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { A1NavigationContentNodeProps } from './types';
import { Context } from '../../context';
import D1StorelocatorIFrame from '../d1-storelocator/module';
import { useLayoutDispatch } from '../../context/layout';
import { Assets } from '../icons/assets';

const A1Navigation = (props) => {
	return (
		<StaticQuery query={graphql`
			query {
				storyblokSpace {
					language_codes
				}
				navigation: allStoryblokEntry(filter: { field_component: {eq: "a1-navigation"} }) {
					edges {
						node {
							lang
							content
						}
					}
				}
			}
		`}
		render={data => <NavigationDisplay data={data} {...props} />}
		/>
	);
};

const NavigationDisplay = ({ data }) => {
	const context = useContext(Context);
	const [showDropdown, setShowdropdown] = useState(false);
	const [isLanguagesExpanded, setIsLanguagesExpanded] = useState(false);
	const { state: { inverseMenu } } = useLayoutDispatch();

	const currentLanguage = useMemo(() => {
		if (context) {
			return context.language;
		}
		return 'en';
	}, [context]);


	const getLanguageContent = (): A1NavigationContentNodeProps => {
		const [ node ] = data.navigation.edges.filter(nav => nav.node.lang === currentLanguage);
		return node;
	};

	const languageContent = useMemo(() => {
		const content = getLanguageContent();
		if (content) {
			return content.node;
		}
		return null;
	}, [currentLanguage]);

	const onClickHandler = () => {
		setShowdropdown(prev => !prev);
	};

	const handleChangeLanguage = (langCode: string) => {
		if (context) {
			return context.setLanguage(langCode);
		}
		return null;
	};

	if (!languageContent) return null;

	const {
		logo,
		home_link,
		navigation_links,
		contact,
		close,
	} = JSON.parse(languageContent.content);

	return (
		<div className={clsx('navigation', { 'navigation--inverted': inverseMenu })}>
			<div className='z-50 flex w-full px-32 mx-auto max-w-screen-2xl'>
				<Link className='z-50 self-start flex-none mt-8 mr-16' to={home_link.cached_url}>
					{logo?.filename && <img className='navigation__logo' src={logo.filename} alt={logo.alt} />}
				</Link>
				<nav className='hidden mx-auto md:flex md:items-center'>
					{navigation_links && navigation_links.map((element, i) => (
						<Link className='navigation__link' key={element.display_text + i} to={element.link.cached_url}>{element.display_text}</Link>
					))}
				</nav>
				<div className='navigation__actions-wrapper'>
					<button className='navigation__store-button' onClick={onClickHandler}>{!showDropdown ? contact :  close }</button>
					<button className='navigation__burger-button' onClick={onClickHandler}>
						<BurgerSVG active={showDropdown} />
					</button>
					<a className='navigation__asset-link' href='https://asset-library.invoqoven.com/' target='_blank' rel='noreferrer' >
						<Assets />
					</a>
					<div className='navigation__language-switcher'>
						<button onClick={() => setIsLanguagesExpanded(!isLanguagesExpanded)}>
							<GlobeIcon />
							<span className='sr-only'>
								Choose language
							</span>
						</button>
						<ul className={clsx('navigation__language-dropdown', {
							'navigation__language-dropdown--expanded': isLanguagesExpanded,
						})}>
							{data.storyblokSpace.language_codes.map((language) =>
								<li
									key={language}
									className={clsx('navigation__language-item', {
										'navigation__language-item--visible': isLanguagesExpanded,
									})}
								>
									<Link
										to={`/${language}`}
										onClick={() => handleChangeLanguage(language)}
										className='navigation__language-button'
									>
										{language}
									</Link>
								</li>,
							)}
						</ul>
					</div>
				</div>
			</div>
			<NavModal active={showDropdown} navigationLinks={navigation_links} />
		</div>
	);
};

const BurgerSVG = ({ active }) => {
	return (
		<svg className='burger-svg' width='30' height='30' stroke='#000' strokeWidth='1'>
			<path className={clsx('burger-svg__path', { 'burger-svg__path--down': active })} d='M6 10, L21 10'>
			</path>
			<path className={clsx('burger-svg__path', { 'burger-svg__path--up': active })} d='M6 18, L21 18'>
			</path>
		</svg>
	);
};

const GlobeIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' className='navigation__globe-icon'>
		<g transform='translate(-1226 -30)'>
			<g id='Ellipse_1' data-name='Ellipse 1' transform='translate(1226 30)' fill='none' stroke='#0d0d0d' strokeWidth='1'>
				<circle cx='7.5' cy='7.5' r='7.5' stroke='none' />
				<circle cx='7.5' cy='7.5' r='7' fill='none' />
			</g>
			<g transform='translate(1228 30)' fill='none' stroke='#0d0d0d' strokeWidth='1'>
				<ellipse cx='5.5' cy='7.5' rx='5.5' ry='7.5' stroke='none' />
				<ellipse cx='5.5' cy='7.5' rx='5' ry='7' fill='none' />
			</g>
			<line y1='14.31' transform='translate(1233.48 30)' fill='none' stroke='#0d0d0d' strokeWidth='1' />
			<line x2='14.31' transform='translate(1226.325 37.48)' fill='none' stroke='#0d0d0d' strokeWidth='1' />
		</g>
	</svg>
);

const NavModal = ({ active, navigationLinks }) => {

	return (
		<div className={clsx('navigation-modal', { 'navigation-modal--active': active })}>
			<div className='navigation-modal__container'>
				<p className='font-light text-white md:hidden text-30'>Select ONE:</p>
				<nav className='flex flex-col md:hidden'>
					{navigationLinks && navigationLinks.map((element, i) => (
						<Link className='navigation-modal__link' key={element.display_text + i} to={element.link.cached_url}>{element.display_text}</Link>
					))}
				</nav>
				<h2 className='text-white'>STORE LOCATOR</h2>
				<D1StorelocatorIFrame />
			</div>
		</div>
	);
};
export default A1Navigation;
