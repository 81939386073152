import React, { useContext, createContext, useReducer } from 'react';

type Action = {type: 'toggle'} | {type: 'invert'} | {type: 'reset'}
type Dispatch = (action: Action) => void
type State = {inverseMenu: boolean}
type LayoutProviderProps = {children: React.ReactNode}

const LayoutContext = createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

const LayoutReducer = (state: State, action: Action) => {
	switch (action.type) {
	case 'toggle':
		return { inverseMenu: !state.inverseMenu };
	case 'invert':
		return { inverseMenu: true };
	case 'reset':
		return { inverseMenu: false };
	default:
		return state;
	}
};

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
	const [state, dispatch] = useReducer(LayoutReducer, { inverseMenu: false });
	const value = {state, dispatch};

	return (
		<LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
	);
};

export const useLayoutDispatch = () => {
	const context = useContext(LayoutContext);
	if (context === undefined) {
		throw new Error('useLayoutDispatch must be used within a LayoutProvider');
	}
	return {...context};
};
