import React, { useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { FooterLinkProps, FooterImageProps } from './types';

const ImageContainer = ({ children }) => (
	<div className='footer__image-container'>{ children }</div>
);

const FooterImage = ({ src, alt }: FooterImageProps) => (
	<img className='footer__image' src={src} alt={alt} />
);

const FooterImageInnerContainer = ({ children }) => (
	<div className='footer__inner-container'>{children}</div>
);

const FooterContent = ({ children }) => (
	<div className='footer__content'>{children}</div>
);

const FooterSocialLinkContainer = ({ children }) => (
	<div className='footer__social-link-container'>{children}</div>
);

const FooterLink = ({ children, url, linkType, gdpr }: PropsWithChildren<FooterLinkProps>) => {

	const urlScheme = useMemo(() => {
		if (url.url) {
			switch(linkType) {
			case 'email':
				return 'mailto:';
			case 'telephone':
				return 'tel:';
			default:
				return null;
			}
		}
		return null;
	}, [url]);

	if (url.url) return (
		<a
			className={clsx('footer__link', {
				'footer__link--gdpr': gdpr,
			})}
			rel='noopener noreferrer'
			href={urlScheme ? urlScheme + url.url : url.url}
			target={urlScheme ? '_self' : '_blank'}
		>
			{children}
		</a>
	);

	return <Link className='footer__link' to={url.cached_url}>{children}</Link>;
};

const FooterTitle = ({ children }) => (
	<p className='footer__title'>{children}</p>
);

const FooterContactContainer = ({ children }) => (
	<div className='footer__contact-container'>{children}</div>
);

const FooterGDPR = ({ children }: PropsWithChildren<unknown>) => (
	<div className='footer__gdpr'>
		{children}
	</div>
);

export {
	FooterContent,
	FooterContactContainer,
	FooterImage,
	FooterImageInnerContainer,
	FooterGDPR,
	FooterSocialLinkContainer,
	FooterLink,
	FooterTitle,
	ImageContainer,
};
