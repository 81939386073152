import React, { useEffect, useRef } from 'react';
import A1Navigation from '../a1-navigation';
import A2Footer from '../a2-footer/module';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { LayoutProvider } from '../../context/layout';
gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children }) => {
	const mainRef = useRef<HTMLDivElement>(null);
	let debounceTimeout: NodeJS.Timeout;
	const debounceDuration = 300;

	useEffect(() => {
		if (mainRef.current !== null ) {
			const resizeObserver = new ResizeObserver(() => {
				clearTimeout(debounceTimeout);
				debounceTimeout = setTimeout(() => ScrollTrigger.refresh(), debounceDuration);
			});
			resizeObserver.observe(mainRef.current);
			return () => resizeObserver.disconnect();
		}
	}, [mainRef]);

	return (
		<div className='layout'>
			<LayoutProvider>
				<A1Navigation />
				<main ref={mainRef} className='layout__container'>
					{children}
				</main>
				<A2Footer />
			</LayoutProvider>
		</div>
	);
};

export default Layout;
