import React from 'react';

const D1StorelocatorIFrame = () => (
	<section className='storelocator'>
		<div className='storelocator__wrapper'>

			<iframe src='/storelocator' width='100%' height='100%' />
		</div>
	</section>
);

export default D1StorelocatorIFrame;
