import React, { createContext, useState, useEffect } from 'react';

type ContextType = {
	language: string;
	setLanguage: (langCode: string) => void;
};

const Context = createContext<ContextType | null>(null);

const { Provider } = Context;

const ContextProvider = ({ children }) => {
	const [language, setLanguage] = useState('en');

	useEffect(() => {
		const storageLanguage = sessionStorage.getItem('langCode');
		if (storageLanguage) {
			setLanguage(storageLanguage);
		}
	}, []);

	const changeCurrentLanguage = (langCode: string) => {
		sessionStorage.setItem('langCode', langCode);
		setLanguage(langCode);
	};

	return (
		<Provider
			value={{
				language,
				setLanguage: (langCode: string) => changeCurrentLanguage(langCode),
			}}
		>
			{children}
		</Provider>
	);
};

export { Context, ContextProvider };
